<template>
  <div class="directory-component">

    <el-button v-if="!$route.meta.fromTopMenu" @click="addTopLevelCategory"
               class="custom-reset-button directory-button">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp;{{ $t('quickDiagnosis.addTopLevelDirectory') }}
    </el-button>

    <el-input v-model="searchKeyword" :placeholder=" $t('quickDiagnosis.pleaseEnterAssetName')"
              class="search-keyword directory-input" clearable>
      <template #suffix>
        <el-icon @click="clearSearch" style="color: black;">
          <search/>
        </el-icon>
      </template>
    </el-input>

    <el-tree :data="treeData" :props="treeProps" @node-click="handleNodeClick" ref="tree" :default-expand-all="true"
             :highlight-current="true"
             :current-node-key="selectedNodeId"
             style="margin-top: 20px;max-height: 700px;overflow-y: auto;" v-loading="loading"
             element-loading-text="Loading...">
      <template v-slot:default="{ data }">
        <TreeNodeActions
            :nodeData="data"
            :isSelected="selectedNodeId === data.key"
            :fetch-tree-data="fetchTreeData"
        />
      </template>
    </el-tree>

  </div>

  <!-- 弹窗内容 -->
  <el-dialog style="text-align: left;"
             :title="$t('quickDiagnosis.addDirectory')"
             v-model="addDialogVisible"
             width="600"
             @closed="resetForm"
             @open="disableScroll" @close="enableScroll"
  >
    <el-form ref="addForm" :model="formData" label-width="100px" :rules="formDataRules">
      <!-- 目录名称 -->
      <el-form-item :label=" $t('quickDiagnosis.directoryName')" prop="directoryName">
        <el-input
            v-model="formData.directoryName"
            :rows="2"
            type="textarea"
            maxlength="50"
            show-word-limit
            :placeholder=" $t('quickDiagnosis.pleaseEnterDirectoryName')"
        />
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="addDialogVisible = false" class="custom-reset-button">{{
          $t('quickDiagnosis.cancel')
        }}
      </el-button>
      <el-button class="button-background-color" @click="addMotorData">{{ $t('quickDiagnosis.confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Plus, Search} from "@element-plus/icons-vue";
import TreeNodeActions from './TreeNodeActions.vue';
import {addFaqCategories, getFaqCategories} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  components: {Search, Plus, TreeNodeActions},
  data() {
    return {
      selectedNodeId: null,
      searchKeyword: "",
      treeData: [],
      treeProps: {
        label: "label",
        children: 'children',
      },
      addDialogVisible: false,
      formData: {
        directoryName: '',
      },
      formDataRules: {
        directoryName: [
          {required: true, message: this.$t('quickDiagnosis.pleaseEnterDirectoryName'), trigger: 'blur'},
        ],
      },
      loading: false, // 添加加载状态
    };
  },
  emits: ['nodeChanged'],
  mixins: [dialogMixin],
  mounted() {
    this.fetchTreeData();
  },
  props: {
    highlightedNodeId: { // 接收父组件传递的 selectedNodeId
      type: String,
      default: null,
    }
  },
  watch: {
    searchKeyword(newVal) {
      if (newVal) {
        this.filterTreeData();
      } else {
        // 如果搜索关键字被清空，重新加载原始数据
        this.fetchTreeData();
      }
    },
    highlightedNodeId(newVal) {
      if (newVal) {
        this.selectedNodeId = newVal;
      }
    }
    /*highlightedNodeId(newVal) {
      if (newVal && this.treeData.length > 0) { // 确保 treeData 已经加载完成
        this.$nextTick(() => {
          if (this.$refs.tree) {
            this.$refs.tree.setCurrentKey(newVal);
          }
        });
      }
    },*/
  },
  methods: {
    addTopLevelCategory() {
      this.addDialogVisible = true;
    },
    handleNodeClick(node) {
      this.selectedNodeId = node.key; // 更新选中的节点 ID
      this.$emit('nodeChanged', node);
    },
    clearSearch() {

    },
    resetForm() {
      this.$refs.addForm.resetFields();
    },
    async addMotorData() {
      await this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          try {
            // 此处假设 addFaqCategories 函数创建新目录，并需要目录名称等信息
            await addFaqCategories({
              name: this.formData.directoryName,
              pid: 0,
              level: 1,
            });
            this.$message.success(this.$t('messages.directoryCreatedSuccessfully'));
            this.addDialogVisible = false; // 关闭对话框
            await this.fetchTreeData(); // 重新获取目录数据，以显示新添加的目录
          } catch (error) {
            this.$message.error(this.$t('errors.createDirectoryFailed'));
          }
        }
      });
    },

    async fetchTreeData() {
      if (this.searchKeyword) return; // 如果当前有搜索关键字，不重新加载数据
      this.loading = true; // 开始加载数据
      try {
        // 注意：实际使用中，请确保该函数名称和用途匹配，这里仅做示例
        const response = await getFaqCategories();
        this.treeData = this.buildTreeStructure(response.data);
      } catch (error) {
        this.$message.error(this.$t('errors.fetchingTreeDataFailed'));
      } finally {
        this.loading = false; // 请求完成后设置loading为false
      }
    },
    buildTreeStructure(data) {
      let map = {}, roots = [];

      data.forEach(item => {
        // 假设 pid 为 null 或特定值时表示顶级节点
        let isTopLevel = !item.pid || item.pid === 0;
        map[item.id] = {...item, children: [], isTopLevel: isTopLevel};
      });

      // 根据pid构建树形结构
      data.forEach(item => {
        let parent = map[item.pid];
        if (parent) {
          // 如果找到父节点，将当前节点添加到父节点的children数组
          parent.children.push(map[item.id]);
        } else {
          // 如果没有找到父节点，说明是根节点
          roots.push(map[item.id]);
        }
      });
      // 将树形结构转换为需要的格式
      return roots.map(root => this.processNode(root));
    },

    processNode(node, parent = null) {
      // 转换每个节点到期望的格式，这里可以根据需要添加或修改属性
      let processedNode = {
        key: node.id.toString(), // 确保key是字符串或数字
        label: node.name,
        showDeleteIcon: false,
        isTopLevel: node.isTopLevel, // 确保保留isTopLevel属性
        children: [],
        parent: parent, // 添加对父节点的引用
      };

      // 遍历子节点并传递当前节点作为parent参数
      node.children.forEach(child => {
        processedNode.children.push(this.processNode(child, processedNode));
      });

      return processedNode;
    },

    // 添加一个方法来过滤 treeData
    filterTreeData() {
      const filter = (data, keyword) => data.filter(item => {
        // 检查当前节点的名称是否包含关键字
        if (item.label.toLowerCase().includes(keyword.toLowerCase())) {
          return true;
        }
        // 如果有子节点，递归检查子节点
        if (item.children) {
          item.children = filter(item.children, keyword);
          return item.children.length;
        }
        return false;
      });

      // 使用定义的 filter 函数过滤 treeData
      this.treeData = filter(this.treeData, this.searchKeyword);
    },
  },
};
</script>

<style scoped>
/* 添加顶部间距给子节点 */
.el-tree-node__label {
  margin-top: 10px;
}

.directory-button, .directory-input {
  width: 250px; /* 根据需要设置宽度 */
}

.search-keyword {
  width: 250px;
  margin-top: 10px;
}

.directory-component {
  width: 250px;
}
</style>
  