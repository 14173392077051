<template>
  <div class="outer-container">
    <!-- 顶部区域 -->
    <div class="top-bar">
      <div class="left-text">{{ categoryAllName }}</div>
      <div class="right-controls">
        <el-select v-model="selectedLanguage" :placeholder="$t('quickDiagnosis.selectLanguage')" filterable
                   @change="addLanguage"
                   class="language-select">
          <el-option v-for="language in languages" :key="language.value" :label="language.label"
                     :value="language.value"></el-option>
        </el-select>
        <el-button @click="cancel" class="custom-reset-button">{{ $t('quickDiagnosis.cancel') }}</el-button>
        <el-button class="button-background-color" @click="publish">{{ $t('quickDiagnosis.publish') }}</el-button>
      </div>
    </div>

    <div class="labels-and-selector">
      <!-- 动态语言标签 -->
      <div class="language-labels">
        <div v-for="(label, index) in languageLabels" :key="index"
             :class="{'active-label': label === currentLanguage, 'inactive-label': label !== currentLanguage}"
             class="language-label" @click="switchLanguage(label)">
          <span v-if="label === 'zh' || label === 'en'" class="asterisk">*</span> {{ index }}
          <el-button type="text" class="delete-button" @click.stop="removeLanguage(index)">
            <el-icon>
              <Close/>
            </el-icon>
          </el-button>
        </div>
      </div>

      <!-- 产品类型选择器 -->
      <div class="product-type-selector">
        <span class="required-asterisk">*</span>{{ $t('quickDiagnosis.productType') }}：&nbsp;&nbsp;&nbsp;
        <el-select v-model="selectedProductType" :placeholder="$t('quickDiagnosis.selectProductType')" filterable
                   class="custom-select">
          <el-option :label="$t('quickDiagnosis.midDrive')" value="mid"></el-option>
          <el-option :label="$t('quickDiagnosis.hubDrive')" value="hub"></el-option>
        </el-select>
      </div>
    </div>

    <!-- 文本框 -->
    <el-input
        type="textarea"
        v-model="languageTitles[currentLanguage]"
        :rows="2"
        :maxlength="200"
        show-word-limit
        :placeholder="$t('quickDiagnosis.enterContent')"
        class="text-area">
    </el-input>

    <!-- 编辑器 -->
    <div ref="editor"></div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import {Close} from "@element-plus/icons-vue";
import {showFaqData, updateFaqs} from "@/api/api";
import EventBus from "@/eventBus";

export default {
  components: {Close},
  data() {
    return {
      quill: null,
      languages: [
        {label: '中文', value: 'zh'},
        {label: 'English', value: 'en'},

        {label: 'Italiano', value: 'it'},    // 意大利语
        {label: 'Francais', value: 'fr'},    // 法语
        {label: 'Deutsch', value: 'de'},     // 德语
        {label: 'Dutch', value: 'nl'},       // 荷兰语
        {label: '日本語', value: 'ja'},        // 日语
        {label: 'Svenska', value: 'sv'},     // 瑞典语
        {label: 'Magyar', value: 'hu'},      // 匈牙利语
        {label: 'Polski', value: 'pl'},      // 波兰语
        { label: 'Español', value: 'es' }, // 新增西班牙语
        { label: 'Português', value: 'pt' } // 新增葡萄牙语
        // ...其他语言
      ],
      selectedLanguage: 'zh',

      languageLabels: {
        '中文': 'zh',
        'English': 'en',
        'Italiano': 'it',    // 意大利语
        'Francais': 'fr',    // 法语
        'Deutsch': 'de',     // 德语
        'Dutch': 'nl',       // 荷兰语
        '日本語': 'ja',        // 日语
        'Svenska': 'sv',     // 瑞典语
        'Magyar': 'hu',      // 匈牙利语
        'Polski': 'pl',      // 波兰语
        'Español': 'es', // 新增西班牙语
        'Português': 'pt'  // 新增葡萄牙语
      }, // 初始时包含中文和英语标签

      languageTitles: {
        'zh': '', // 中文内容的初始值
        'en': '', // 英文内容的初始值
        'it': '',  // 意大利语内容的初始值
        'fr': '',  // 法语内容的初始值
        'de': '',  // 德语内容的初始值
        'nl': '',  // 荷兰语内容的初始值
        'ja': '',  // 日语内容的初始值
        'sv': '',  // 瑞典语内容的初始值
        'hu': '',  // 匈牙利语内容的初始值
        'pl': '',  // 波兰语内容的初始值
        'es': '', // 新增西班牙语
        'pt': ''  // 新增葡萄牙语
      }, // 存储不同语言的内容
      languageContents: {
        //'zh': '', // 中文内容的初始值
        //'en': '', // 英文内容的初始值
      }, // 存储不同语言的内容
      currentLanguage: 'zh', // 当前选中的语言
      selectedProductType: 'hub', // 用于存储选中的产品类型
      faqId: null, // 当前编辑的FAQ ID
      existingContents: [], // 记录已有的内容
      categoryAllName: '',
      selectedNodeId: null, // 添加属性来存储selectedNodeId
    };
  },
  async created() {

    this.faqId = this.$route.params.id; // 获取路由参数中的FAQ ID
    await this.fetchFaqData(); // 获取FAQ数据
    if (this.$route.query.selectedNodeId) {
      // 从路由参数中获取selectedNodeId
      this.selectedNodeId = this.$route.query.selectedNodeId;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initQuill();
      this.$nextTick(() => {
        this.switchLanguage(this.currentLanguage); // 确保编辑器初始化后切换到当前语言
      });
    });
  },
  selectedNodeId() {
    return this.$route.query.selectedNodeId;
  },
  methods: {
    async fetchFaqData() {
      try {
        const faqData = await showFaqData(this.faqId);

        this.selectedProductType = faqData.data.product_type === 0 ? 'mid' : 'hub';
        this.existingContents = faqData.data.contents;
        faqData.data.contents.forEach(content => {
          this.languageTitles[content.lang] = content.title;
          this.languageContents[content.lang] = content.content;
          this.languageContents[`${content.lang}_id`] = content.id; // 保存内容ID
        });
        this.categoryAllName = faqData.data.category_name;
        this.switchLanguage(this.currentLanguage);
      } catch (error) {
        this.$message.error(this.$t('quickDiagnosis.fetchFaqDataFailed'));
      }
    },
    initQuill() {
      const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{header: 1}, {header: 2}],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{script: 'sub'}, {script: 'super'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{direction: 'rtl'}],
        [{size: ['small', false, 'large', 'huge']}],
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        [{color: []}, {background: []}],
        [{font: []}],
        [{align: []}],
        ['clean'],
        ['link', 'image', 'video']
      ];

      this.quill = new Quill(this.$refs.editor, {
        theme: 'snow',
        modules: {toolbar: toolbarOptions}
      });
    },
    addLanguage() {
      let newLabel = this.languages.find(lang => lang.value === this.selectedLanguage).value;
      if (newLabel && !Object.values(this.languageLabels).includes(newLabel)) {
        this.languageLabels[newLabel] = newLabel;
        this.languageTitles[this.selectedLanguage] = '';
        this.languageContents[this.selectedLanguage] = '';
      }
      this.switchLanguage(newLabel);
    },

    removeLanguage(index) {
      const language = this.languageLabels[index];
      // 检查是否是“中文”或“English”标签
      if (language === 'zh' || language === 'en') {
        this.$message.warning(`${index} ${this.$t('quickDiagnosis.cannotBeRemoved')}`);
        return;
      }
      delete this.languageLabels[index];

      delete this.languageTitles[language];
      delete this.languageContents[language];
      if (this.currentLanguage === language) {
        const remainingLanguages = Object.keys(this.languageLabels);
        this.currentLanguage = remainingLanguages.length > 0 ? this.languageLabels[remainingLanguages[0]] : '';
        this.switchLanguage(this.currentLanguage);
      }
    },
    switchLanguage(language) {
      if (this.currentLanguage && this.quill) {
        const currentContent = this.quill.root.innerHTML;
        if (currentContent !== '<p><br></p>') { // 仅在有实际内容时保存
          this.languageContents[this.currentLanguage] = currentContent;
        }
      }
      // 先保存当前语言的内容
      this.currentLanguage = language;
      if (this.languageContents[language]) {
        this.quill.root.innerHTML = this.languageContents[language];
      } else {
        this.quill.root.innerHTML = '';
      }

    },
    cancel() {
      this.$router.push({name: 'FAQManagement', params: {selectedNodeId: this.selectedNodeId}});
    },
    async publish() {
      try {
        if (this.currentLanguage && this.quill) {
          this.languageContents[this.currentLanguage] = this.quill.root.innerHTML;
        }
        // 检查必填项，包括中文和英文
        const requiredLanguages = ['zh', 'en'];
        for (const lang of requiredLanguages) {
          if (Object.prototype.hasOwnProperty.call(this.languageTitles, lang) && Object.prototype.hasOwnProperty.call(this.languageContents, lang)) {
            const title = this.languageTitles[lang];
            const content = this.languageContents[lang];

            if (!title.trim() || content.trim() === '<p><br></p>') {
              this.$message.error(this.$t('quickDiagnosis.missingRequiredFields'));
              return;
            }
          } else {
            this.$message.error(this.$t('quickDiagnosis.missingRequiredFields'));
            return;
          }
        }

        // 构建更新数据，过滤掉那些被删除的语言（即标题和内容都为空）
        const updateData = {
          product_type: this.selectedProductType === 'mid' ? 0 : 1,
          contents: Object.values(this.languageLabels)
              .map(lang => {
                const title = this.languageTitles[lang] || ''; // 确保 title 不为空
                const content = this.languageContents[lang] || ''; // 确保 content 不为空
                const id = this.languageContents[`${lang}_id`] || null; // 确保 id 处理正确

                // 如果标题和内容都为空，则不传递该语言
                if (!title.trim() && content.trim() === '<p><br></p>') {
                  return null;
                }
                return {
                  lang: lang,
                  title: title.trim(), // 去掉多余的空格
                  content: content.trim() || '', // 确保内容不为 null
                  id: id // 如果有 id，则传递
                };
              })
              // 过滤掉为 `null` 的项（即被删除的语言）
              .filter(entry => entry.title.trim() && entry.content.trim() !== '<p><br></p>')
        };

        await updateFaqs(this.faqId, updateData);
        this.$message.success(this.$t('quickDiagnosis.publishSuccess'));

        // 通知父组件编辑完成，保持目录选中状态
        EventBus.emit('editCompleted', this.selectedNodeId);

        this.$router.push({
          name: 'FAQManagement',
          query: {selectedNodeId: this.selectedNodeId} // 使用 query 传递 selectedNodeId
        });
      } catch (error) {
        this.$message.error(this.$t('quickDiagnosis.publishFailed'));
      }
    },
  }
};
</script>

<style scoped>
.outer-container {
  margin: 10px;
}

.custom-select {
  width: 100px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-text {
  font-size: 14px;
}

.language-labels {
  display: flex;
  align-items: center;
}

.language-label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 5px;
  color: green;
  min-width: 90px;
  font-size: 14px;
  cursor: pointer;
}

.active-label {
  background-color: white;
  color: rgb(78, 152, 78);
}

.inactive-label {
  background-color: rgb(250, 250, 250);
  color: rgb(37, 37, 37);
}

.asterisk {
  color: red;
  margin-right: 5px;
}

.delete-button {
  color: rgb(140, 140, 140);
  margin-left: 10px;

}

.right-controls {
  display: flex;
  align-items: center;
}

.language-select {
  margin-right: 10px;
  width: 100px; /* 或者您希望的任何宽度 */
}

.ql-container {
  height: 200px;
}

/* 为新的文本框添加样式 */
.text-area {
  width: 100%; /* 设置宽度为100% */
  margin-top: 10px; /* 在文本框和上方元素之间添加一些间隔 */
  margin-bottom: 20px;
}

/* 语言标签和产品类型选择器的容器样式 */
.labels-and-selector {
  display: flex;
  justify-content: space-between; /* 在两端分布内容 */
  align-items: center; /* 垂直居中对齐 */
  margin-bottom: 10px;
}

.language-labels {
  display: flex;
  align-items: center;
}

.product-type-selector {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.required-asterisk {
  color: red;
  margin-right: 5px;
}


/* 自定义工具栏样式 */
.ql-toolbar {
  justify-content: left; /* 工具栏靠左对齐 */
}

/* 为工具栏按钮添加悬停效果 */
.ql-toolbar .ql-button:hover {
  background-color: #f2f2f2; /* 鼠标悬停时的背景色 */
}

</style>
