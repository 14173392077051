<template>
  <!-- 添加返回箭头图标和点击事件处理函数 -->
  <hr class="separator " style="margin-top: 1px"/>

  <div class="faq-management">
    <div class="directory">
      <!-- 左侧目录组件 -->
      <DirectoryComponent @nodeChanged="handleNodeSelection" :highlightedNodeId="selectedNodeId" />
    </div>

    <div class="content">
      <!-- 右侧内容组件 -->
      <ContentComponent :isButtonEnabled="isButtonEnabled"
                        :showAddButton="!isFromTopMenu"
                        :selectedNodeId="selectedNodeId"
      />
    </div>
  </div>
</template>

<script>
import DirectoryComponent from "./components/DirectoryComponent"; // 路径根据实际情况调整
import ContentComponent from "./components/ContentComponent";
import EventBus from "@/eventBus";

export default {
  components: {
    DirectoryComponent,
    ContentComponent,
  },
  data() {
    return {
      backArrowClicked: false, // 新增状态
      isButtonEnabled: false, // 控制右侧按钮是否可点击
      // 初始化表格数据为空数组
      selectedNodeId: null,
    };
  },
  computed: {
    isFromTopMenu() {
      return this.$route.meta.fromTopMenu;
    },
  },
  mounted() {
    //EventBus.on('editCompleted', this.refreshDirectorySelection);
    EventBus.on('editCompleted', (nodeId) => this.refreshDirectorySelection(nodeId)); // 用箭头函数确保 this 指向正确
  },
  created() {
    // 接收路由参数中的 selectedNodeId
    /*if (this.$route.query.selectedNodeId) {
      this.selectedNodeId = this.$route.query.selectedNodeId;
    }*/
  },
  beforeUnmount() {
    EventBus.off('editCompleted', this.refreshDirectorySelection);
  },
  methods: {
    refreshDirectorySelection(nodeId) {
      //this.selectedNodeId = nodeId;
      // 这里可以进一步传递 selectedNodeId 给目录组件
      // 重置 selectedNodeId 以确保高亮更新
      this.selectedNodeId = null;

      this.$nextTick(() => {
        // 重新设置 selectedNodeId，强制触发高亮
        this.selectedNodeId = nodeId;
      });

    },
    async handleNodeSelection(selectedNode) {
      try {
        this.selectedNodeId = selectedNode.key;
        this.isButtonEnabled = true;
      } catch (error) {
        // 如果getFaqs抛出了错误，这里捕获并处理
        console.error(this.$t('quickDiagnosis.nodeProcessingError'));
      }
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/publicStyle.css";

.faq-management {
  display: flex;
  position: relative; /* 相对定位的容器 */
}

.directory {
  padding: 10px;
  border-right: 1px solid #e0e0e0;
}

.content {
  width: 90%; /* 调整右侧内容宽度 */
  padding: 20px;
}
</style>
  